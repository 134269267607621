export enum WorksetType {
    regular = 1,
    warmup,
    failure,
    dropset
}

export enum WorkoutSupersetType {
    field = 'event_fieldworkout',
    strength = 'event_strengthworkout',
}